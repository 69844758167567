import AbstractService from '@/services/abstractService'

class InquiriesService extends AbstractService {
  endpoint = 'inquiries'
  put(id, data, params = null) {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }
}

const Service = new InquiriesService()

export default Service
