<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <div
    id="showModal" class="modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <div class="">
          <b-card-code
            title="Inquiry Content"
          >
          <b-row>
            <b-col md="12">
              <b-form-group>
                  <b-form-textarea
                    v-model="contactRequest.message"
                    name="Content"
                    :readonly="true"
                    placeholder=""
                  />
              </b-form-group>
            </b-col>
          </b-row>
          </b-card-code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BFormGroup, BRow, BCol, BFormTextarea
} from 'bootstrap-vue'
import { codeMultiple, codeBasic } from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
  },
  props: ['item'],
  data() {
    return {
      codeMultiple,
      contactRequest: {
        message: ''
      },
      codeBasic,
    }
  },
  mounted() {
    this.$watch(() => this.item, val => {
      this.contactRequest = {
        ...val
      }
    })
  },
  methods: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}
textarea {
  height: 400px!important;
}
#showModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
