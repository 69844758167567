<template>
  <div>
    <Spinner v-if="loading === true" />
    <div
      v-if="loading === false"
      class="row"
    />
    <basic-table
      v-if="loading === false"
      ref="table"
      :columns="columns"
      :values="rows"
      :editable-page="false"
      :actions-obj="actionsArray"
      @dynamicAction="handleActions($event)"
      @choosenObject="getEditedObject($event)"
      @deleteItems="removeItems($event)"
      @deletedMultipleIds="catchIds($event)"
    />
    <show :item="showInquiry" />
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Spinner from '@/shared/loader/Spinner'
import BasicTable from '../../../shared/tables/BasicTable.vue'
import inquiriesService from '../../../services/inquiriesService'
import Show from './show.vue'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Show,
    BasicTable,
  },
  data() {
    return {
      loading: false,
      showInquiry: '',
      userId: '',
      actionsArray: [
        { title: 'Show', icon: 'EyeIcon' },
      ],
      editedFaq: '',
      roleId: '',
      columns: [
        {
          label: 'User',
          field: 'user.full_name',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Email ',
          field: 'email',
        },
        {
          label: 'Phone ',
          field: 'phone',
        },
        {
          label: 'Date',
          field: 'createdAt',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getInquiriesRequests()
  },
  methods: {
    getInquiriesRequests() {
      this.loading = true
      inquiriesService.index().then(response => {
        this.rows = response.data.data
        this.loading = false
      })
    },
    handleActions(itemObj) {
      switch (itemObj.key) {
        case 'Show':
          this.showRequest(itemObj.id)
          break
      }
    },
    showRequest(inquiryId) {
      this.showInquiry = this.rows.filter(el => el.id === inquiryId)[0]
      $('#showModal').modal()
    },
    getEditedObject(obj) {
      this.editedFaq = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.loading = true
      this.confirmDeleteText(arr)
    },
    confirmDeleteText(arr) {
      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            inquiriesService.delete(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => {
              this.loading = false
              return Promise.reject(error)
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    renderTable() {
      this.getInquiriesRequests()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
